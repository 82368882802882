import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Button from "@mui/material/Button";
import CheckoutForm from "./payments/PaymentForm";
import FormHelperText from "@mui/material/FormHelperText";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  lightBlue,
} from "@mui/material/colors";
import { ImHammer2 } from "react-icons/im";
import axios from "axios";
import "./payments/payments.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import LegalFeesSelection from "./LegalFeesSelectionForm";

const theme = createTheme({
  palette: {
    primary: lightBlue,
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.5rem", // Increase the legend size here
          color: "linear-gradient(45deg, #ff1744 30%, #FF8E53 90%)",
          fontWeight: "bolder",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: "75px",
        },
      },
    },
  },
});

const ComplaintAgainstForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);


  const classes = "";
  const [showPlansForm, setShowPlansForm] = useState(false)
  const [complaintTitle, setComplaintTitle] = useState("");
  const [description, setDescription] = useState("");
  const [complaintType, setComplaintType] = useState("");
  const [investmentAmt, setInvestmentAmt] = useState("");
  const [incidentDate, setIncidentDate] = useState("");
  const [incidentPlace, setIncidentPlace] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [apiErr, setApiErr] = useState("")
  const [errors, setErrors] = useState({
    complaintTitle: "",
    description: "",
    complaintType: "",
    investmentAmt: "",
    incidentDate: "",
    incidentPlace: "",
  });

  useEffect(() => {
    if (apiErr) {
      setTimeout(() => {
        setApiErr("")
      },3000)
    }
  },[apiErr])


  const validateForm = () => {
    let valid = true;
    const newErrors = {
      complaintTitle: "",
      description: "",
      complaintType: "",
      investmentAmt: "",
      incidentDate: "",
      incidentPlace: "",
    };

    if (!complaintTitle
      .trim()) {
      newErrors.complaintTitle = "Complaint title is required";
      valid = false;
    }

    if (!description) {
      newErrors.description = "Description is required";
      valid = false;
    } 

    if (!complaintType.trim()) {
      newErrors.complaintType = "Complaint type is required";
      valid = false;
    } 
    
    if (!investmentAmt.trim()) {
      newErrors.incidentDate ="Investment amount is required";
      valid = false;
    }

    if (!incidentDate) {
      newErrors.incidentPlace = "Incident date is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleComplaintAgainstForm = async (event) => {
    event?.preventDefault();
    if (validateForm()) {
      
      try {
        const response = await axios.post(
          "https://www.consumercomplaints.info/legalmotocall.php",
          {
            complaintTitle: complaintTitle,
            complaintDescription: description,
            complaintType: complaintType,
            investmentAmt: investmentAmt,
            incidentDate: incidentDate,
            incidentPlace: incidentPlace,
          },
          {
            headers: {
              "x-api-secret": "XrKylwnTF3GpBbmgiCbVxYcCMkNvv8NHYdh9v5am",
            },
          }
        );
        console.log(response);
        if (response?.data?.status == "true") {
          sessionStorage?.removeItem("plan");
          // navigate("/checkout");
          setShowPlansForm(true)
        } else {
          setApiErr(response?.data?.message)
          console.log("Network response was not ok.");
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setShowLoader(false);
      }
    } else {
      setShowLoader(false);
    }
  };



  return (
    <>
      {showLoader && (
        <div className="preloader">
          <div className="cssload-dots">
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
          </div>
        </div>
      )}

      {isSubmitted ? (
        <ThemeProvider theme={theme}>
          <div className="bg-[rgba(0,0,0,0.1)] !text-white !h-[100%]  ">
            <CheckoutForm />
          </div>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <div className="bg-[rgba(0,0,0,0.2)] !text-white !h-[100%] ">
            {!showPlansForm && (
              <>
                <h3
                  className="text-center h-[7vh] mb-0 flex items-center justify-center complaintAgainestFormTitle"
                  // style={{
                  //   background: "linear-gradient(45deg, red, transparent)",
                  // }}
                >
                  Complaint against
                  <b className="ml-2 truncate max-w-[275px]">
                    {sessionStorage.getItem("complaintAgst")}
                  </b>
                </h3>
                <form
                  className="bg-[rgba(0,0,0,0.2)] !text-white"
                  onSubmit={handleComplaintAgainstForm}
                >
                  <div className="row flex justify-center">
                    <div className="col-md-10">
                      <TextField
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "#333",
                          // border: "1px solid black",
                          //borderRadius:"10px"
                        }}
                        className="!text-white bg-[rgba(255,255,255,1)] text-[#333]"
                        // label="Full Name"
                        placeholder="Complaint title(शिकायत शीर्षक)*"
                        variant="outlined"
                        value={complaintTitle}
                        onChange={(event) =>
                          setComplaintTitle(event.target.value?.trimStart())
                        }
                        fullWidth
                        margin="normal"
                        error={errors.complaintTitle}
                      />
                      {errors.complaintTitle && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none "
                          style={{
                            background: "rgba(250,0,0,0.4)",
                            fontSmooth: "always",
                          }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.complaintTitle}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    <div className=" col-md-10">
                      <TextField
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "#333",
                          width: "100%",
                          // border: "1px solid black",
                          //borderRadius:"10px"
                        }}
                        // label="Email Id "
                        placeholder="Description(विवरण)*"
                        variant="outlined"
                        value={description}
                        onChange={(event) =>
                          setDescription(event?.target?.value)
                        }
                        fullWidth
                        margin="normal"
                        error={errors.description}
                      />

                      {errors.description && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none"
                          style={{ background: "rgba(250,0,0,0.4)" }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.description}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    <div className=" col-md-10">
                      <TextField
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "#333",
                          // border: "1px solid black",
                          //borderRadius:"10px"
                        }}
                        //label="Phone Number"
                        placeholder="Complaint type(शिकायत प्रकार)*"
                        variant="outlined"
                        value={complaintType}
                        onChange={(event) =>
                          setComplaintType(event.target.value)
                        }
                        fullWidth
                        margin="normal"
                        error={!!errors.complaintType}
                      />
                      {errors.complaintType && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none"
                          style={{ background: "rgba(250,0,0,0.4)" }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.complaintType}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    {/* city */}
                    <div className="col-md-10">
                      <TextField
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "#333",
                        }}
                        className="!text-white"
                        placeholder="Investment amount(निवेश राशि)*"
                        variant="outlined"
                        value={investmentAmt}
                        onChange={(event) =>
                          setInvestmentAmt(event.target.value?.trim())
                        }
                        fullWidth
                        margin="normal"
                        error={errors.investmentAmt}
                      />
                      {errors.investmentAmt && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none "
                          style={{
                            background: "rgba(250,0,0,0.4)",
                            fontSmooth: "always",
                          }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.investmentAmt}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    <div className="col-md-10">
                      <DatePicker
                        className="py-[10.5px] px-[14px] mt-[16px] mb-[8px] !w-[100%]] !border-r-0"
                        showTimeSelect
                        dateFormat="Pp"
                        value={incidentDate}
                        // //label="Complaint Against"
                        placeholderText="Incident date(घटना दिनांक)?"
                        selected={incidentDate}
                        onChange={(date) => setIncidentDate(date)}
                        error={!!errors.incidentDate}
                      />
                      {errors.incidentDate && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none"
                          style={{ background: "rgba(250,0,0,0.4)" }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.incidentDate}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    {/* refuncd amount */}
                    <div className="col-md-10">
                      <TextField
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "#333",
                        }}
                        className="!text-white"
                        type="number"
                        placeholder="Approx. Refund Amount(अनुमानित वापसी राशि) in INR"
                        variant="outlined"
                        value={incidentPlace}
                        onChange={(event) =>
                          setIncidentPlace(event.target.value?.trimStart())
                        }
                        fullWidth
                        margin="normal"
                        error={errors.incidentPlace}
                      />
                      {errors.incidentPlace && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none "
                          style={{
                            background: "rgba(250,0,0,0.4)",
                            fontSmooth: "always",
                          }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.incidentPlace}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    {apiErr && <p className="text-red-600">{apiErr} </p>}
                    <div className="col-md-12 flex items-center justify-center pb-10 mt-3">
                      <Button
                        className="btn btn-white btn-animated "
                        type="submit"
                        variant="contained"
                        size="4vw"
                        style={{
                          margin: theme.spacing(1),
                          fontWeight: "bold",
                          background:
                            "linear-gradient(45deg, #ff1744 30%, #FF8E53 90%) !important",
                          color: "white",
                          width: "60%",
                          height: "6vh",
                          fontSize: "larger",
                        }}
                      >
                        <ImHammer2 className="mr-2" />
                        Submit Complaint
                      </Button>
                    </div>
                  </div>
                </form>
              </>
            )}
            {showPlansForm && <LegalFeesSelection />}
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default ComplaintAgainstForm;
