import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CheckoutForm from "./payments/PaymentForm";
import FormHelperText from "@mui/material/FormHelperText";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  lightBlue,
} from "@mui/material/colors";
import { ImHammer2 } from "react-icons/im";
import axios from "axios";
import "./payments/payments.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import ComplaintAgainstForm from "./ComplaintAgainestForm";

const theme = createTheme({
  palette: {
    primary: lightBlue,
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.5rem", // Increase the legend size here
          color: "linear-gradient(45deg, #ff1744 30%, #FF8E53 90%)",
          fontWeight: "bolder",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: "75px",
        },
      },
    },
  },
});

const ComplaintFormRefConsumerComplaint = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showComplaintAgainestForm, setShowComplaintAgainestForm] = useState(false)
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("India");
  const [complaint, setComplaint] = useState("");
  const [refundAmt, setRefundAmt] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [phoneCode, setPhonecode] = useState("91");
  const [showLoader, setShowLoader] = useState(false);
  const [apiErr, setApiErr] = useState("")
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    complaint: "",
    refundAmt: ""
  });


  useEffect(() => {
    if (apiErr) {
      setTimeout(() => {
        setApiErr("");
      },3000)
    
  }
},[apiErr])

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      email: "",
      phone: "",
      city:"",
      complaint: "",
      refundAmt: ""
    };

    if (!name) {
      newErrors.name = t("name_is_required");
      valid = false;
    }

    if (!email) {
      newErrors.email = t("Email_is_required");
      valid = false;
    } else if (!isValidEmail(email)) {
      newErrors.email = t("Invalid_email_address");
      valid = false;
    }

    if (!phone) {
      newErrors.phone = t("Phone_is_required");
      valid = false;
    } else if (!isValidPhone(phone)) {
      newErrors.phone = t("Invalid_phone_number");
      valid = false;
    }
    if (!city) {
      newErrors.city = t("City_is_required");
      valid = false;
    }

    if (!complaint) {
      newErrors.complaint = t("Complaint_is_required");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const submitFormDataTwo = async (event) => {
    event?.preventDefault();
    if (validateForm()) {
      const encryptedEmail = CryptoJS.AES.encrypt(
        JSON.stringify({ email: email }),
        "legalmotocompolaintform"
      );
      console.log(encryptedEmail);
      try {
        const response = await axios.post(
          "https://www.consumercomplaints.info/legalmotocall.php",
          {
            firstname: name,
            email: email,
            phone: phone,
            city: city,
            complaint: complaint,
            refundAmt: refundAmt,
            encrypt: encryptedEmail?.toString(),
          },
          {
            headers: {
              "x-api-secret": "XrKylwnTF3GpBbmgiCbVxYcCMkNvv8NHYdh9v5am",
              // "enc-info": "legalmotocompolaintform",
            },
          }
        );
        console.log(response)
        if (response?.data?.status == "true") {
          sessionStorage?.removeItem("plan");
          sessionStorage.setItem("complaintAgst", complaint)
          setShowComplaintAgainestForm(true)
          // navigate("/checkout");
        } else {
          console.log(response);
          setApiErr(response?.data?.message)
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setApiErr(error?.response?.data?.message)
        setShowLoader(false);
      }
    } else {
      setShowLoader(false);
    }
  };


  const handleEmailchange = async (e) => {
    setEmail(e.target.value?.trim());
    if (isValidEmail(e.target.value?.trim())) {
      try {
        const response = await axios.get(
          `https://admin.thelegalmoto.com/api/client/check-email?email=${e.target.value?.trim()}`,
          {
            headers: {
              "x-api-secret": "XrKylwnTF3GpBbmgiCbVxYcCMkNvv8NHYdh9v5am",
            },
          }
        );
        if (response?.data?.status) {
          const errObj = errors;
          if (response?.data?.type === "client") {
            errObj.email = t("Email Already Registered");
            errObj.name = "";
            errObj.phone = "";
            errObj.complaint = "";
            errObj.country = "";
            setName("");
            setEmail("");
            setPhone("");
            setComplaint("");
            setCountry("India");
            setPhonecode("60");
            setErrors(errObj);
          } else if (response?.data?.type === "lead") {
            setShowLoader(true);
            localStorage.setItem("twToken", response?.data?.token);
            sessionStorage.setItem("twToken", response?.data?.token);
            sessionStorage.setItem(
              "name",
              response?.data?.data?.client?.firstname
            );
            sessionStorage.setItem(
              "email",
              response?.data?.data?.client?.email
            );
            sessionStorage.setItem(
              "phone",
              response?.data?.data?.client?.phone
            );
            sessionStorage.setItem(
              "country",
              response?.data?.data?.client?.country
            );
            sessionStorage.setItem(
              "complaint",
              response?.data?.data?.complaint?.description
            );
            sessionStorage.removeItem("plan");
            navigate("/checkout");
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const isValidEmail = (email) => {
    // Simple email validation, you can use a library or more complex logic here
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    // Simple phone number validation, you can use a library or more complex logic here
    const phoneRegex = /^\d{8,15}$/;
    return phoneRegex.test(phone);
  };

  const complaintByUser = () => {
    return <span className="font-bold">{"kjg"}</span>;
  }

  return (
    <>
      {showLoader && (
        <div className="preloader">
          <div className="cssload-dots">
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
            <div className="cssload-dot"></div>
          </div>
        </div>
      )}
      {isSubmitted ? (
        <ThemeProvider theme={theme}>
          <div className="bg-[rgba(0,0,0,0.1)] !text-white !h-[100%]  ">
            {/* <h3
              className="text-center h-[7vh] mb-0 flex items-center justify-start pl-4"
              style={{ background: "linear-gradient(45deg, red, transparent)" }}
            >
              {!isSubmitted
                ? `${t("Online_Complaint_To_Advocate")}`
                : `Hello Sandeep,`}
            </h3> */}
            <h3
              className="text-center h-[7vh] mb-0 flex items-center justify-start pl-4 complaintAgainestFormTitle"
              // style={{ background: "linear-gradient(45deg, red, transparent)" }}
            >
              {t("Online_Complaint_To_Advocate")}
            </h3>
            <CheckoutForm />
          </div>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <div className="bg-[rgba(0,0,0,0.2)] !text-white !h-[100%] ">
            {showComplaintAgainestForm && <ComplaintAgainstForm />}
            {!showComplaintAgainestForm && (
              <>
                <h3
                  className="text-center h-[7vh] mb-0 flex items-center justify-center"
                  style={{
                    background: "linear-gradient(45deg, red, transparent)",
                  }}
                >
                  {t("Online_Complaint_To_Advocate")}
                </h3>
                <form
                  className="bg-[rgba(0,0,0,0.2)] !text-white"
                  onSubmit={submitFormDataTwo}
                >
                  <div className="row flex justify-center">
                    <div className="col-md-10">
                      <TextField
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "#333",
                          // border: "1px solid black",
                          //borderRadius:"10px"
                        }}
                        className="!text-white"
                        // label="Full Name"
                        placeholder="Your Name(आपका नाम)"
                        variant="outlined"
                        value={name}
                        onChange={(event) =>
                          setName(event.target.value?.trimStart())
                        }
                        fullWidth
                        margin="normal"
                        error={errors.name}
                      />
                      {errors.name && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none "
                          style={{
                            background: "rgba(250,0,0,0.4)",
                            fontSmooth: "always",
                          }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.name}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    <div className=" col-md-10">
                      <TextField
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "#333",
                          width: "100%",
                          // border: "1px solid black",
                          //borderRadius:"10px"
                        }}
                        // label="Email Id "
                        placeholder="Your Email(तुम्हारा ईमेल)"
                        variant="outlined"
                        value={email}
                        onChange={(event) => handleEmailchange(event)}
                        fullWidth
                        margin="normal"
                        error={errors.email}
                      />

                      {errors.email && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none"
                          style={{ background: "rgba(250,0,0,0.4)" }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.email}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    <div className=" col-md-10">
                      <TextField
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "#333",
                          // border: "1px solid black",
                          //borderRadius:"10px"
                        }}
                        //label="Phone Number"
                        placeholder="Mobile Number(मोबाइल नंबर)"
                        variant="outlined"
                        value={phone}
                        onChange={(event) =>
                          setPhone(event.target.value?.slice(0, 10)?.trim())
                        }
                        fullWidth
                        margin="normal"
                        error={!!errors.phone}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <span
                                className="font-bold pr-2"
                                style={{ borderRight: "1px solid #dee2e6" }}
                              >
                                {phoneCode}
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {errors.phone && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none"
                          style={{ background: "rgba(250,0,0,0.4)" }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.phone}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    {/* city */}
                    <div className="col-md-10">
                      <TextField
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "#333",
                        }}
                        className="!text-white"
                        placeholder="City(शहर)*"
                        variant="outlined"
                        value={city}
                        onChange={(event) =>
                          setCity(event.target.value?.trimStart())
                        }
                        fullWidth
                        margin="normal"
                        error={errors.city}
                      />
                      {errors.city && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none "
                          style={{
                            background: "rgba(250,0,0,0.4)",
                            fontSmooth: "always",
                          }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.city}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    <div className="col-md-10">
                      <TextField
                        type="textarea"
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "red",
                          // border: "1px solid black",
                          //borderRadius:"10px"
                        }}
                        // //label="Complaint Against"
                        placeholder="Complaint Against(के खिलाफ शिकायत)?"
                        variant="outlined"
                        value={complaint}
                        onChange={(event) =>
                          setComplaint(event.target.value?.trimStart())
                        }
                        fullWidth
                        multiline
                        margin="normal"
                        error={!!errors.complaint}
                      />
                      {errors.complaint && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none"
                          style={{ background: "rgba(250,0,0,0.4)" }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.complaint}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    {/* refuncd amount */}
                    <div className="col-md-10">
                      <TextField
                        style={{
                          backgroundColor: "rgba(255,255,255,1)",
                          color: "#333",
                        }}
                        className="!text-white"
                        type="number"
                        placeholder="Approx. Refund Amount(अनुमानित वापसी राशि) in INR"
                        variant="outlined"
                        value={refundAmt}
                        onChange={(event) =>
                          setRefundAmt(event.target.value?.trimStart())
                        }
                        fullWidth
                        margin="normal"
                        error={errors.refundAmt}
                      />
                      {errors.refundAmt && (
                        <div
                          className=" !pl-3  pt-1 pb-1 !rounded-none "
                          style={{
                            background: "rgba(250,0,0,0.4)",
                            fontSmooth: "always",
                          }}
                          role=""
                        >
                          <FormHelperText
                            className="text-white font-bold"
                            error
                          >
                            {errors.refundAmt}
                          </FormHelperText>
                        </div>
                      )}
                    </div>
                    {apiErr && (
                      <p className="text-danger text-center w-full mb-0 mt-3">
                        {apiErr}
                      </p>
                    )}
                    <div
                      className={`col-md-5 flex items-center justify-center pb-10 mt-3`}
                    >
                      <Button
                        className="btn btn-white btn-animated "
                        type="submit"
                        variant="contained"
                        size="4vw"
                        style={{
                          margin: theme.spacing(1),
                          fontWeight: "bold",
                          background:
                            "linear-gradient(45deg, #ff1744 30%, #FF8E53 90%) !important",
                          color: "white",
                          width: "60%",
                          height: "6vh",
                          fontSize: "larger",
                        }}
                      >
                        <ImHammer2 className="mr-2" />
                        Next
                      </Button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default ComplaintFormRefConsumerComplaint;
